.dashboard.page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.status .high{
  background-image: url(../../assets/svgs/warning.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #DE0A0A;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.status .medium{
  background-image: url(../../assets/svgs/warning.svg);
  background-size: 20px;
  background-position: center;
  background-color: #DE9B0A;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.status .low{
  background-image: url(../../assets/svgs/warning.svg);
  background-size: 20px;
  background-position: center;
  background-color: #DEDB0A;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.status .ok{
  background-image: url(../../assets/svgs/check.svg);
  background-size: 20px;
  background-position: center;
  background-color: #0ade0a;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.status .dropdown{
  background-image: url(../../assets/svgs/arrow-down-black.svg);
  fill: white;
  background-position: center;
  height: 20px;
  width: 20px;
}

.prio .high{
  background-image: url(../../assets/svgs/warning.svg);
  background-size: 20px;
  background-position: center;
  background-color: #DE0A0A;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.prio .medium{
  background-image: url(../../assets/svgs/warning.svg);
  background-size: 20px;
  background-position: center;
  background-color: #DE9B0A;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.prio .low{
  background-image: url(../../assets/svgs/warning.svg);
  background-size: 20px;
  background-position: center;
  background-color: #DEDB0A;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.prio .informational{
  background-image: url(../../assets/svgs/info.svg);
  background-size: 20px;
  background-position: center;
  background-color: #EDEDED;
  border-radius: 20px;
  height: 20px;
  width: 20px;
}

.dashboard .inner .prio .resolved{
  min-width: 50px;
  width: unset;
}

.dashboard .inner .date {
  min-width: 100px;
  width: unset;
}

/* .dashboard .inner .time {
  padding: 0 25px 0 0;
} */

.dashboard .inner .date .name,
.dashboard .inner .year .name,
.dashboard .inner .time .name {
  width: 100%;
}

.dashboard .inner .arrow {
  min-width: max-content;
  margin: 5px 10px 0 0;
  width: unset;
}

.dashboard .inner .event-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0px !important;
  min-height: 20px;
  min-width: 20px;
}

.dashboard .inner .arrow .leave {
    background-image: url(../../assets/svgs/door-white.svg);
}

.dashboard .inner .arrow .soeb {
    background-image: url(../../assets/images/edge-of-bed.png);
    filter: invert(1);
}

.dashboard .inner .arrow .man-down {
    background-image: url(../../assets/images/man-losing-hat.png);
    filter: invert(1);
}

.dashboard .inner .arrow .name {
  text-align: left;
  width: 100%;
}

.dashboard .inner .expand{
  align-items: center;
  flex-direction: row;
  justify-content: center;
  min-width: 35px;
  max-width: 35px;
}

.dashboard .inner .expand .icon{
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 20px;
  opacity: 0.9;
  width: 15px;
}

.dashboard .inner .header {
  font-weight: bold;
  margin: 10px 0px 10px 0px;
}

.action-container {
  display: flex;
  padding: 0px 0px 0px 6px;
  margin: 24px 0 0 0;
  width: 25%;
}

.time-container {
  display: flex;
  padding: 0px 0px 0px 6px;
  width: 50%;
}

.page-inner .actions .dashboard-search-bar {
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  padding: 6px 9px 5px 6px;
  margin: 25px 0 0px 0;
  width: 100%;
}

.page-inner .actions .dashboard-search-bar .icon {
  background-image: url(../../assets/svgs/search-dark.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  margin: 2px 4px 0 0;
  transition: 0.2s;
  width: 21px;
}

.page-inner .actions .dashboard-search-bar input {
  border: none;
  font-size: 14px;
  padding: 0;
  margin: auto 0;
  width: 100%;
}

.dashboard.page .page-inner .list-items{
  /* height: calc(100vh - 222px);
  overflow-x: hidden;
  overflow-y: auto; */
}

.dashboard.page .page-inner .list-items .list-item .info.time,
.dashboard.page .page-inner .legend-bar .legend-item.time {
  min-width: 100px;
}

.dashboard.page .page-inner .list-items .list-item .info.type,
.dashboard.page .page-inner .legend-bar .legend-item.type {
  min-width: 100px;
}

.dashboard.page .page-inner .list-items .list-item .info.date,
.dashboard.page .page-inner .legend-bar .legend-item.date {
  width: 100%;
}

.dashboard.page .page-inner .list-items .list-item .info.arrow,
.dashboard.page .page-inner .legend-bar .legend-item.arrow {
  width: 100px;
}

/* Flash class and keyframe animation */
.flashing{
	-webkit-animation: flash linear 1.5s infinite;
	animation: flash linear 1.5s infinite;
  border-radius: 5px;
}
@-webkit-keyframes flash {
	0% { background-color: rgba(39, 40, 41, 0.9); } 
	50% { background-color: rgba(222, 10, 10, 0.9); }
  100% { background-color: rgba(39, 40, 41, 0.9); } 
}
@keyframes flash {
	0% { background-color: rgba(39, 40, 41, 0.9); } 
	50% { background-color: rgba(222, 10, 10, 0.9); }
  100% { background-color: rgba(39, 40, 41, 0.9); } 
}